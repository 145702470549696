import 'react-loading-skeleton/dist/skeleton.css';

import { ChevronRightSVG } from '@sbercloud/uikit-product-icons';
import { ButtonFunction } from '@snack-uikit/button';
import { Scroll } from '@snack-uikit/scroll';
import { Search } from '@snack-uikit/search';
import { Tabs } from '@snack-uikit/tabs';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  SearchClickAnalyticsSource,
  useAnalytics,
} from 'src/hooks/useAnalytics';
import { useElasticProductsSearch } from 'src/hooks/useElasticProductsSearch';
import { useFilteredProducts } from 'src/hooks/useFilteredProducts';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useHeaderPosition } from 'src/hooks/useHeaderPosition';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { HeaderCardProduct } from 'src/layout/Header/components/HeaderCardProduct';
import { CategoryType, ProductsSectionProps } from 'src/layout/Header/types';
import { PLATFORMS } from 'src/mocks/products/platforms';
import { CategoryList } from 'src/snack/components/CategoryList';
import { PlatformSelect } from 'src/snack/components/PlatformSelect';
import {
  type CatalogProduct,
  type ProductCategory,
} from 'src/types/backendContent';

import s from './Products.module.scss';

const HEADER_DESKTOP_WIDTH = 1269;

export const PLATFORMS_CATEGORY = [
  {
    id: 'platforms',
    title: 'Платформы',
    slug: 'platforms',
    description:
      'более 80+ Iaas, Pass, ML сервисов на&nbsp<span class="categoryDescription">4 платформах</span>',
    src: 'https://cdn.cloud.ru/backend/layout/header/platforms_icon.svg',
  },
];

const MAX_RENDER_COUNT = 6;

function AllProductsButton({
  onClick,
  countProducts,
}: {
  onClick?: () => void;
  countProducts: number;
}) {
  const { clickAnalytics } = useAnalytics();
  const router = useRouter();
  const href = '/products';
  const label = `Все продукты (${countProducts})`;

  return (
    <ButtonFunction
      label={label}
      href={href}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
        clickAnalytics({
          action: href,
          clickZone: 'header',
          clickElement: 'button',
          clickContent: label,
          uniqueId: 'header-menu-products-button-to-all-products',
          transitionType: 'inside-link',
        });
        router.push(href);
      }}
      size="m"
      appearance="neutral"
      icon={<ChevronRightSVG />}
      iconPosition="after"
      className={s.allProductsButton}
    />
  );
}

function MarketplaceButton({ onClick }: { onClick?: () => void }) {
  const { clickAnalytics } = useAnalytics();

  const router = useRouter();

  const href = '/marketplace';
  const label = 'Маркетплейс';

  return (
    <ButtonFunction
      label={label}
      href={href}
      onClick={(e) => {
        e.preventDefault();

        onClick && onClick();

        clickAnalytics({
          action: href,
          clickZone: 'header',
          clickElement: 'button',
          clickContent: label,
          uniqueId: 'header-menu-products-button-to-marketplace',
          transitionType: 'inside-link',
        });

        router.push(href);
      }}
      size="m"
      appearance="neutral"
      icon={<ChevronRightSVG />}
      iconPosition="after"
      className={s.allProductsButton}
    />
  );
}

function RefProgramButton({ onClick }: { onClick?: () => void }) {
  const { clickAnalytics } = useAnalytics();

  const href = '/partners/referral';
  const label = 'Реферальная программа';

  return (
    <ButtonFunction
      label={label}
      href={href}
      target="_blank"
      onClick={() => {
        onClick && onClick();

        clickAnalytics({
          action: href,
          clickZone: 'header',
          clickElement: 'button',
          clickContent: label,
          uniqueId: 'header-menu-products-button-to-partners-referral',
          transitionType: 'inside-link',
        });
      }}
      size="m"
      appearance="neutral"
      icon={<ChevronRightSVG />}
      iconPosition="after"
      className={s.allProductsButton}
    />
  );
}

function Menu({
  platformFilter,
  onPlatformFilterChange,
  categories,
  categoryFilter,
  onCategoryFilterChange,
  onClickForClose,
  countProducts,
}: {
  platformFilter: string | undefined;
  categories: ProductCategory[];
  categoryFilter: string | undefined;
  onCategoryFilterChange: (categoryId: string | undefined) => void;
  onPlatformFilterChange: (platformId: string | undefined) => void;
  onClickForClose?: () => void;
  countProducts: number;
}) {
  const { headerHeight } = useHeaderPosition();

  return (
    <div
      className={s.menu}
      style={{
        maxHeight: `calc(100vh - ${headerHeight}px - 104px)`,
      }}
    >
      <PlatformSelect
        platforms={PLATFORMS}
        value={platformFilter}
        onChange={onPlatformFilterChange}
      />
      <CategoryList
        categories={categories}
        value={categoryFilter}
        onChange={onCategoryFilterChange}
        singleMode
        classNames={{
          root: s.categoryList,
        }}
      />

      <div className={s.additionalLinks}>
        <AllProductsButton
          onClick={onClickForClose}
          countProducts={countProducts}
        />
        <MarketplaceButton onClick={onClickForClose} />
        <RefProgramButton onClick={onClickForClose} />
      </div>
    </div>
  );
}

function TitleSearch({
  titleFilter,
  category,
  platformTitle = '',
  count,
  loading,
  onClickForClose,
}: {
  titleFilter: string;
  category?: CategoryType;
  platformTitle?: string;
  count: number;
  loading: boolean;
  onClickForClose?: () => void;
}) {
  const { clickAnalytics } = useAnalytics();
  const { getAdaptiveSizeTypography } = useWindowWidth();

  const router = useRouter();
  const buttonLabel =
    count > MAX_RENDER_COUNT
      ? `Еще продукты (${count - MAX_RENDER_COUNT})`
      : 'Еще продукты';

  const href = `/products?platform=${platformTitle}&category=${category?.slug}`;

  return (
    <div
      className={cn(s.blockHeader, {
        [s.notFoundTitle]: count < 1 && !loading,
      })}
    >
      {count < 1 && !loading && (
        <Typography.SansTitleM>Ничего не найдено</Typography.SansTitleM>
      )}

      {(count > 0 || loading) && (
        <Typography
          family="sans"
          tag="h2"
          className={s.title}
          {...getAdaptiveSizeTypography({
            desktop: { purpose: 'title', size: 'l' },
            tablet: { purpose: 'title', size: 'm' },
          })}
        >
          {titleFilter.length > 0 ? 'Результаты поиска' : category?.title}
        </Typography>
      )}

      {titleFilter?.length < 1 && count > 0 && (
        <ButtonFunction
          label={buttonLabel}
          href={href}
          onClick={(e) => {
            e.preventDefault();
            clickAnalytics({
              action: href,
              clickZone: 'header',
              clickElement: 'button',
              clickContent: 'Еще продукты',
              uniqueId: `header-menu-products-button-to-all-products-with-platform-${platformTitle.toUpperCase()}-category-${category?.title.toUpperCase()}`,
              transitionType: 'inside-link',
            });
            onClickForClose && onClickForClose();
            router.push(href);
          }}
          size="m"
          appearance="neutral"
          icon={<ChevronRightSVG />}
          iconPosition="after"
        />
      )}
    </div>
  );
}

function ProductsList({
  filteredProducts,
  titleFilter,
  debouncedPhrase,
  category,
  platformTitle,
  loading,
  onClickForClose,
}: {
  filteredProducts: CatalogProduct[];
  titleFilter: string;
  debouncedPhrase: string;
  category?: CategoryType;
  platformTitle?: string;
  loading: boolean;
  onClickForClose?: () => void;
}) {
  const { sendDataToDataLayer } = useGtmDataLayer();
  const { clickAnalytics, searchClickAnalytics } = useAnalytics();

  const sendDataLayer = (label: string) => {
    const dataLayerToSend = {
      dataLayer: {
        event: 'custom_event',
        event_category: 'click',
        event_action: 'click_header_product_card',
        event_label: label,
      },
    };

    sendDataToDataLayer(dataLayerToSend);
  };

  const onCardClick = (
    title: string,
    link: string,
    source?: SearchClickAnalyticsSource,
  ) => {
    sendDataLayer(title);
    if (debouncedPhrase.length > 0) {
      searchClickAnalytics({
        link: link,
        searchPhrase: debouncedPhrase,
        type: 'menu-products',
        source: source,
      });
    } else {
      clickAnalytics({
        action: link,
        clickZone: 'header',
        clickElement: 'cardclick',
        clickContent: title,
        uniqueId: `header-menu-products-card-click-${title}`,
        transitionType: 'inside-link',
      });
    }
    onClickForClose && onClickForClose();
  };

  const renderHeaderCardProduct = (product: CatalogProduct) => {
    const href = product.customLink
      ? `/${product.customLink}`
      : product.type === 'solutions'
      ? `/solutions/${product.slug}`
      : `/products/${product.slug}`;

    const filteredTags = product?.tags
      ? product.tags.filter((tag) => !tag.hideInCatalog)
      : [];

    return (
      <HeaderCardProduct
        key={product.id}
        onClickForClose={() =>
          onCardClick(product.title, href, product.analyticSource)
        }
        title={product.title}
        text={product.description}
        icon={product.icon}
        link={href}
        productTags={filteredTags}
      />
    );
  };

  const sliceProducts =
    debouncedPhrase.length > 0
      ? filteredProducts
      : filteredProducts.slice(0, MAX_RENDER_COUNT);

  return (
    <div className={s.itemsWrapper}>
      <div className={s.block}>
        <TitleSearch
          titleFilter={titleFilter}
          category={category}
          platformTitle={platformTitle}
          count={filteredProducts.length}
          loading={loading}
          onClickForClose={onClickForClose}
        />
        {sliceProducts.length > 0 && (
          <div
            className={cn(s.blockGrid, {
              [s.blockGridPlatforms]: category?.title === 'platforms',
            })}
          >
            {!loading ? (
              sliceProducts.map(renderHeaderCardProduct)
            ) : (
              <ProductsListSkeleton />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function ProductsListSkeleton() {
  const skeletonCount = 4;
  return Array.from({ length: skeletonCount }).map((_, index) => (
    <div className={s.skeletonProductCard} key={index}>
      <Skeleton width={40} height={40} borderRadius={0} />
      <Skeleton height={40} width={'100%'} borderRadius={0} />
    </div>
  ));
}

function ComplexSearchOffer({
  titleFilter,
  onClickForClose,
}: {
  titleFilter: string;
  onClickForClose?(): void;
}) {
  const { clickAnalytics } = useAnalytics();
  const pathname = usePathname();
  const href = `/products?search=${titleFilter}`;

  return (
    <a
      href={href}
      className={s.smartSearchLink}
      onClick={() => {
        clickAnalytics({
          action: href,
          clickZone: 'header',
          clickElement: 'textclick',
          clickContent: 'Поиск продуктов по всем платформам',
          uniqueId: `header-menu-products-search-by-all-products-with-query-${titleFilter}`,
          transitionType: 'inside-link',
        });

        if (pathname?.indexOf('search') === 1) {
          onClickForClose && onClickForClose();
        }
      }}
    >
      <Typography.SansBodyL
        // TODO: как то прокинуть not found статус, чтобы добавит стили notFoundSearchAll
        className={s.searchAll}
      >
        Поиск <span className={s.link}>продуктов по всем платформам</span>
      </Typography.SansBodyL>
    </a>
  );
}

function TabletHeader({ products, onClickForClose }: ProductsSectionProps) {
  const { clickAnalytics } = useAnalytics();
  const {
    categoriesByPlatform,
    filteredProducts,
    platformFilter,
    categoryFilter,
    titleFilter,
    setPlatformFilter,
    setCategoryFilter,
    setTitleFilter,
  } = useFilteredProducts({
    initialPlatformFilter: PLATFORMS[0].slug,
    initialCategoryFilter: products.categories[0].slug,
    categories: products.categories,
    products: products.items,
  });

  const { searched, loading, debouncedPhrase } = useElasticProductsSearch({
    phrase: titleFilter.trim(),
    products: products.items,
    filteredProducts: filteredProducts,
    platform: platformFilter,
    typeAnalytics: 'live-menu-products',
  });

  useEffect(() => {
    setCategoryFilter(categoriesByPlatform[0].slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesByPlatform]);

  const handleFocusSearch = () => {
    clickAnalytics({
      action: 'click',
      clickZone: 'header',
      clickElement: 'input',
      clickContent: 'Поиск продукта',
      uniqueId: 'header-menu-products-search-input',
      transitionType: 'inside-link',
    });
  };

  const handleChangeFilter = (value: string) => {
    value.trim().length > 180
      ? setTitleFilter(value.slice(0, 180))
      : setTitleFilter(value);
  };

  return (
    <div className={s.root}>
      <div className={s.searchWrapper}>
        <Search
          placeholder="Поиск продукта"
          size="l"
          value={titleFilter}
          onChange={handleChangeFilter}
          className={cn(s.search, s.border)}
          onFocus={handleFocusSearch}
        />
      </div>
      <div className={s.platformSelectWrapper}>
        <PlatformSelect
          platforms={PLATFORMS}
          value={platformFilter}
          onChange={setPlatformFilter}
        />
      </div>

      <Tabs value={categoryFilter}>
        <Tabs.TabBar className={s.tabBar}>
          {categoriesByPlatform.map((category) => {
            return (
              <Tabs.Tab
                label={category.title}
                value={category.slug}
                onClick={() => setCategoryFilter(category.slug)}
                key={`${category.slug}_${category.title}`}
              />
            );
          })}
        </Tabs.TabBar>
        {categoriesByPlatform.map((category, index) => {
          return (
            <Tabs.TabContent
              key={`${category.slug}_${index}`}
              value={category.slug}
            >
              <ProductsList
                filteredProducts={searched}
                titleFilter={titleFilter.trim()}
                debouncedPhrase={debouncedPhrase}
                category={category}
                loading={loading}
                onClickForClose={onClickForClose}
              />

              {titleFilter.trim().length > 0 && (
                <ComplexSearchOffer
                  titleFilter={titleFilter.trim()}
                  onClickForClose={onClickForClose}
                />
              )}
            </Tabs.TabContent>
          );
        })}
      </Tabs>

      <div className={s.additionalLinks}>
        <AllProductsButton
          onClick={onClickForClose}
          countProducts={products.items.length}
        />
        <MarketplaceButton onClick={onClickForClose} />
        <RefProgramButton onClick={onClickForClose} />
      </div>
    </div>
  );
}

function DesktopHeader({ products, onClickForClose }: ProductsSectionProps) {
  const { clickAnalytics } = useAnalytics();
  const {
    categoriesByPlatform,
    filteredProducts,
    platformFilter,
    categoryFilter,
    titleFilter,
    setPlatformFilter,
    setCategoryFilter,
    setTitleFilter,
  } = useFilteredProducts({
    initialPlatformFilter: PLATFORMS[0].slug,
    initialCategoryFilter: products.categories[0].slug,
    categories: products.categories,
    products: products.items,
  });

  const { searched, loading, debouncedPhrase } = useElasticProductsSearch({
    phrase: titleFilter.trim(),
    products: products.items,
    filteredProducts: filteredProducts,
    platform: platformFilter,
    typeAnalytics: 'live-menu-products',
  });

  const { headerHeight } = useHeaderPosition();

  const activeCategory = products.categories.find(
    (category) => category.slug === categoryFilter,
  );

  const handlePlatformFilterChange = (platform?: string) => {
    if (platform) {
      clickAnalytics({
        action: 'click',
        clickZone: 'header',
        clickElement: 'filter-select',
        clickContent: platform,
        uniqueId: `header-menu-products-platform-filter-select-${platform}`,
        transitionType: 'inside-link',
      });
    }
    setPlatformFilter(platform);
  };

  const handleCategoryFilterChange = (category?: string) => {
    if (category) {
      clickAnalytics({
        action: 'click',
        clickZone: 'header',
        clickElement: 'filter-select',
        clickContent: category,
        uniqueId: `header-menu-products-category-filter-select-${category}`,
        transitionType: 'inside-link',
      });
    }
    setCategoryFilter(category);
  };

  const handleFocusSearch = () => {
    clickAnalytics({
      action: 'click',
      clickZone: 'header',
      clickElement: 'input',
      clickContent: 'Поиск продукта',
      uniqueId: 'header-menu-products-search-input',
      transitionType: 'inside-link',
    });
  };

  const handleChangeFilter = (value: string) => {
    value.trim().length > 180
      ? setTitleFilter(value.slice(0, 180))
      : setTitleFilter(value);
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <Menu
          categories={categoriesByPlatform}
          platformFilter={platformFilter}
          categoryFilter={categoryFilter}
          onPlatformFilterChange={handlePlatformFilterChange}
          onCategoryFilterChange={handleCategoryFilterChange}
          onClickForClose={onClickForClose}
          countProducts={products.items.length}
        />
        <div className={s.contentWrapper}>
          <Scroll size="s">
            <div
              className={s.right}
              style={{
                maxHeight: `calc(100vh - ${headerHeight}px - 104px)`,
              }}
            >
              <div className={s.searchWrapper}>
                <Search
                  className={cn(s.search, s.border)}
                  placeholder="Поиск продукта"
                  size="l"
                  value={titleFilter}
                  onChange={handleChangeFilter}
                  onFocus={handleFocusSearch}
                />
              </div>
              <ProductsList
                filteredProducts={searched}
                titleFilter={titleFilter.trim()}
                debouncedPhrase={debouncedPhrase}
                category={activeCategory}
                platformTitle={platformFilter}
                loading={loading}
                onClickForClose={onClickForClose}
              />

              {titleFilter.trim().length > 0 && (
                <ComplexSearchOffer
                  titleFilter={titleFilter.trim()}
                  onClickForClose={onClickForClose}
                />
              )}
            </div>
          </Scroll>
        </div>
      </div>
    </div>
  );
}

export function Products({ products, onClickForClose }: ProductsSectionProps) {
  const { windowWidth } = useWindowWidth();

  return windowWidth && windowWidth > HEADER_DESKTOP_WIDTH ? (
    <DesktopHeader products={products} onClickForClose={onClickForClose} />
  ) : (
    <TabletHeader products={products} onClickForClose={onClickForClose} />
  );
}
